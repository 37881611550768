import React from "react";

const WelcomePage = () => {
  return (
    <div className="">
      <h1>Welcome!</h1>
      <p>This is your blank welcome page.</p>
    </div>
  );
};

export default WelcomePage;
