import { useMemo, useState } from "react";
import { _navigation } from "../modules/_navigation";
import { Header } from "../parts/Header";
import { _auth } from "../modules/_auth";
import Sidebar from "../partials/Sidebar";
import { Store } from "./store";
import { HeaderPrivate } from "../parts/HeaderPrivate";

const AssessmentLayout = ({ children }) => {
  const profile = _auth.getUserProfile();
  const navigation = useMemo(
    () =>
      _navigation.getNavLinksByTenantType(
        "admin",
        "assessmentLayout"
        // profile && profile.tenantType ? profile.tenantType : "admin"
      ),
    [profile]
  );
  console.log("assessmentLayout navigation", navigation);

  return (
    <Store>
      <div className="flex flex-col h-screen overflow-auto">
        {/* Sidebar */}
        {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

        {/* Content area */}
        <div className=" relative flex flex-col flex-1  overflow-auto">
          <HeaderPrivate profile={profile} navigation={navigation} />
          {/*  Site header */}
          {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

          <main>{children}</main>
        </div>
      </div>
    </Store>
  );
};

export default AssessmentLayout;
