import { useEffect } from "react";
import HeroImage from "../images/heroimage.jpg";
import { LinkIcon } from "@heroicons/react/24/outline";
import Button from "../elements/Button";
import { NavLink } from "react-router-dom";
export function Hero(props) {
  const title = props.title;
  const subtitle = props.subtitle;
  const image = props.image;
  const description = props.description;
  const buttons = props.buttons;

  return (
    <>
      <div
        id="top"
        className="text-white text-opacity-80 relative overflow-hidden h-screen w-screen"
      >
        <div className="h-full w-full absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
          <div className="h-full  w-full xl:grid xl:grid-cols-1">
            {/* <div className="h-full xl:relative xl:col-start-2"> */}
            <img
              className=" object-cover  object-top w-full h-full xl:absolute xl:inset-0"
              src={HeroImage}
              alt=""
            />
            <div className="absolute inset-0 h-full w-full    " />
          </div>
        </div>
        <div className="h-full justify-center content-center max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-full lg:px-8 xl:grid xl:grid-cols-1 xl:grid-flow-col-dense xl:gap-x-8">
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className=" text-center text-4xl tracking-tight sm:text-5xl md:text-6xl lg:text-7xl">
              <span className="inline block font-bold mb-5">{props.title}</span>
              <span className="inline block  font-bold mb-5"></span>
              {/* <span className="inline block  font-bold mb-5">
                link
              </span> */}
            </h1>
            <h2 className="text-center font-display text-2xl tracking-tight sm:text-4xl">
              {subtitle}
            </h2>
            <p className="text-center mt-4 text-lg tracking-tight max-w-large mx-auto sm:max-w-3xl">
              {description}
            </p>

            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex justify-center">
              <div className="flex justify-center  space-y-4 sm:space-y-0 m-10 sm:inline-grid sm:grid-cols-1 sm:gap-5">
                <NavLink
                  key={"gallery"}
                  to={"/public/gallery"}
                  className="w-full md:w-48 flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-900 bg-white  sm:px-8"
                >
                  Free Preview
                </NavLink>
              </div>
              <div className="flex justify-center  space-y-4 sm:space-y-0 m-10  sm:inline-grid sm:grid-cols-1  sm:gap-5">
                {/* <NavLink
                  key={"signup"}
                  to={"/public/signup"}
                  className="w-full md:w-48 flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-neutral-900 hover:bg-neutral-800 sm:px-8"
                >
                  Sign up
                </NavLink> */}
              </div>
              <div className="flex md:hidden justify-center  space-y-4 sm:space-y-0 m-10  sm:inline-grid sm:grid-cols-1  sm:gap-5">
                <form
                  action="https://www.paypal.com/donate"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="business" value="B95A6UFPDHR46" />
                  <input type="hidden" name="no_recurring" value="0" />
                  <input type="hidden" name="currency_code" value="USD" />
                  {/* <input
                      type="image"
                      src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
                      border="0"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                    /> */}
                  <button
                    className="w-full h-8 whitespace-nowrap rounded-full  flex items-center justify-center px-4 py-3 
                     border border-transparent text-base font-semibold  shadow-sm text-[#1F3284]
                     bg-[#F0D151] bg-opacity-90 hover:bg-opacity-95 sm:px-8"
                    type="submit"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                  >
                    Donate with PayPal
                  </button>
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </div>
            </div>
            {/* <div className="m-25">
              <p className="mt-25 text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
                Built by veterans in eDiscovery, CyberSecurity, Privacy, and
                Breach
              </p>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover"
                src={HeroImage}
                alt="People working on laptops"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">{props.title}</span>
                <span className="block text-indigo-200">{props.subtitle}</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                {props.descrption}
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <a
                    href="#"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                  >
                    Get started
                  </a>
                  <a
                    href="#"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                  >
                    Live demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
