import React, { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
const Gallery = (props) => {
  const [index, setIndex] = useState(-1);
  const { data } = props;
  return (
    <div>
      <PhotoAlbum
        layout="masonry"
        photos={
          data
            ? data
            : [
                {
                  src: "https://via.placeholder.com/800",
                  width: 800,
                  height: 600,
                },
                {
                  src: "https://via.placeholder.com/1600",
                  width: 1600,
                  height: 900,
                },
              ]
        }
        targetRowHeight={150}
        onClick={({ index }) => setIndex(index)}
      />
      {/* <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} /> */}

      <Lightbox
        slides={
          data
            ? data
            : [
                {
                  src: "https://via.placeholder.com/800",
                  width: 800,
                  height: 600,
                },
                {
                  src: "https://via.placeholder.com/1600",
                  width: 1600,
                  height: 900,
                },
              ]
        }
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div>
  );
};

export default Gallery;
