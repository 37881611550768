import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition";
import Button from "../elements/Button";
import DropdownImage from "../images/logo.jpg";
import DropdownImage01 from "../images/logo.jpg";
import DropdownImage02 from "../images/channel-02.png";
import DropdownImage03 from "../images/channel-03.png";
import {
  ArrowDownIcon,
  ArrowSmDownIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";

function DropdownSwitch(
  { align, profile, handleTenantSwitch, setCreateTeamOpen, tenantsToShow },
  props
) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [selected, setSelected] = useState({ id: "", name: "" });

  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    console.log("TenantList profile", profile);
    setSelected({ id: profile.tenantId, name: profile.tenantName });
  }, []);

  useEffect(() => {
    tenantsToShow.sort((a, b) =>
      a.tenantId.tenantTitle.toLowerCase() >
      b.tenantId.tenantTitle.toLowerCase()
        ? 1
        : -1
    );
    setTenantList(tenantsToShow);
  }, [tenantsToShow]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleCreateTeamClick = async () => {
    console.log("handleCreateTeam");

    setCreateTeamOpen(true);
  };

  // handle tenant switch
  const handleSwitch = (id, name, type) => {
    // e.preventDefault();
    console.log("handleTenantSwitch", id, name);
    const newTenant = { id: id, name: name, type: type };
    setSelected(newTenant);
    handleTenantSwitch(newTenant);
  };

  return (
    <div className="relative">
      <button
        ref={trigger}
        className="flex items-center justify-center"
        // className="w-24 justify-center items-center px-4 py-4 border border-gray-800 leading-5 font-medium "
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {/* <div className=" truncate align-center"> */}
        <div className=" text-base ">{selected.name}</div>

        <ChevronDownIcon
          className="ml-2 flex mt-auto mb-auto w-5 h-5  "
          aria-hidden="true"
        />

        {/* </div> */}
      </button>
      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-60 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <ul
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {tenantList.map((tenant) => {
            console.log("Tenant List", tenant);
            let id = tenant.tenantId ? tenant.tenantId._id : null;
            let tenantName = tenant.tenantId
              ? tenant.tenantId.tenantTitle
              : null;
            let type = tenant.tenantId ? tenant.tenantId.type : null;

            return (
              <li className="py-1">
                <a
                  className="font-medium text-sm text-slate-600  block py-1.5 px-3"
                  href="#0"
                  onClick={() => handleSwitch(id, tenantName, type)}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center truncate">
                      <img
                        className="w-6 h-6  mr-3"
                        src={DropdownImage01}
                        width="28"
                        height="28"
                        alt="Channel 01"
                      />
                      <div className="truncate">{tenantName}</div>
                    </div>
                    {selected.id === id && (
                      <svg
                        className="w-3 h-3 shrink-0 fill-primary-900 text-primary-800 ml-2"
                        viewBox="0 0 12 12"
                      >
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                    )}
                  </div>
                </a>
              </li>
            );
          })}
          <li className="border-t">
            <a
              className="font-medium text-sm text-accent-600 hover:text-accent-700 block py-3 px-3 "
              href="#0"
              onClick={() => handleCreateTeamClick()}
            >
              <div className="flex items-center justify-between">
                <div className="grow flex items-center truncate">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 ml-2 mr-3 "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>

                  <div className="truncate text-sm">Create Team</div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </Transition>
    </div>
  );
}

export default DropdownSwitch;
