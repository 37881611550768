import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import HeaderLinks from "../partials/header/HeaderLinks";
import Logo from "../images/BreachConnectLogoBlue.png";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { FeatureFlag } from "../components/FeatureFlag";
import { set } from "date-fns";
import Button from "../elements/Button";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export function Header(props) {
  const profile = props.profile;
  const [currentLink, setCurrentLink] = useState("home");
  const [navigation, setNavigation] = useState([]);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setNavigation(props.navigation);
  }, [props.navigation]);

  const handleNavClick = (scrollTo, linkName) => {
    // setCurrentLink(linkName);
    executeScroll(scrollTo, linkName);
  };

  const executeScroll = (scrollTo, linkName) => {
    // console.info("executeScroll", hubsForm);
    setCurrentLink(linkName);

    const element = document.getElementById(scrollTo);
    console.info("executeScroll", element);
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      // block: "nearest",
      inline: "start",
    });
  };
  return (
    <>
      <header
        style={{ backgroundColor: "#000000" }}
        className="sticky top-0 z-20  w-full"
      >
        <Popover className="relative w-full ">
          <div className="w-full flex justify-between items-center  mx-auto px-4 py-4 sm:px-6 md:justify-start md:space-x-10 ">
            <div className="flex m-auto lg:inline xl:inline 2xl:inline 3xl:inline 4xl:inline ">
              <div className="flex flex-row justify-center content-center">
                {/* <img width="50" className="mr-3 " src={Logo} /> */}
                <span className="inline-flex text-3xl m-auto font-medium text-slate-200">
                  Midnight Paranormal
                </span>
              </div>
            </div>

            {profile && profile !== null && (
              <div className="hidden sm:hidden md:hidden lg:hidden xl:inline 2xl:inline 3xl:inline 4xl:inline ">
                <HeaderLinks
                  showHome={false}
                  align="left"
                  navigation={navigation ? navigation : []}
                />
              </div>
            )}
            <div className="bg-neutral-950 ml-8 hidden md:flex items-center justify-start md:flex-1 lg:w-0">
              {(!profile || (profile && profile == null)) && (
                <>
                  <div>
                    <NavLink
                      to={"/"}
                      key={"home"}
                      // onClick={() => handleNavClick("top", "/")}
                      className={`font-medium ${
                        pathname === "/" && !currentLink === "/"
                          ? "text-white"
                          : "text-white opacity-80"
                      } hover:text-white opacity-80`}
                    >
                      Home
                    </NavLink>
                  </div>
                  <div className="ml-8">
                    <NavLink
                      key={"gallery"}
                      to={"/public/gallery"}
                      // onClick={() => executeScroll("vetBanner", "gallery")}
                      className={`font-medium ${
                        pathname.includes("#") && currentLink === "gallery"
                          ? "text-white"
                          : "text-white opacity-80"
                      } hover:text-white opacity-80`}
                    >
                      Free Preview
                    </NavLink>
                  </div>
                </>
              )}
              {/* <div className="ml-8">
                <NavLink
                  to={"#"}
                  key={"pricing"}
                  onClick={() => executeScroll("pricing", "pricing")}
                  className={`font-medium ${
                    pathname === "/" && currentLink === "pricing"
                      ? "text-indigo-600"
                      : "text-gray-500"
                  } hover:text-gray-600`}
                >
                  Pricing
                </NavLink>
              </div> */}
              {/* <div className="ml-8">
                <a
                  href="/pricing"
                  className={`font-medium ${
                    pathname.includes("pricing")
                      ? "text-indigo-600"
                      : "text-gray-500"
                  } hover:text-gray-600`}
                >
                  Pricing
                </a>
              </div> */}

              {/* <FeatureFlag type="flag" featureName="Pricing"> */}
              {/* <div className="ml-8">
                <a
                  href="/pricing"
                  className={`text-base font-medium ${
                    pathname.includes("pricing")
                      ? "text-indigo-600"
                      : "text-gray-500"
                  } hover:text-gray-900`}
                ></a>
              </div> */}
              {/* </FeatureFlag> */}
            </div>
            {(!profile || profile == null) && (
              <div className="mr-8 hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <div className="ml-8">
                  <form
                    action="https://www.paypal.com/donate"
                    method="post"
                    target="_top"
                  >
                    <input
                      type="hidden"
                      name="business"
                      value="B95A6UFPDHR46"
                    />
                    <input type="hidden" name="no_recurring" value="0" />
                    <input type="hidden" name="currency_code" value="USD" />
                    {/* <input
                      type="image"
                      src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
                      border="0"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                    /> */}
                    <button
                      className="w-full h-8 whitespace-nowrap rounded-full  flex items-center justify-center px-4 py-3 
                     border border-transparent text-base font-semibold  shadow-sm text-[#1F3284]
                     bg-[#F0D151] bg-opacity-90 hover:bg-opacity-95 sm:px-8"
                      type="submit"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                    >
                      Donate with PayPal
                    </button>
                    <img
                      alt=""
                      border="0"
                      src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                      width="1"
                      height="1"
                    />
                  </form>
                </div>
                <div className="ml-8">
                  {/* <NavLink
                    key={"gallery"}
                    to={"/public/login"}
                    className=" whitespace-nowrap text-base font-medium hover:text-opacity-50 text-white"
                  >
                    Login
                  </NavLink> */}
                </div>
                {/* <NavLink
                  key={"gallery"}
                  to={"/public/signup"}
                  // onClick={() => executeScroll("vetBanner", "gallery")}
                  className="ml-8 whitespace-nowrap  items-center justify-center  bg-yellow-800 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white text-opacity-80 hover:from-indigo-700 hover:to-indigo-700"
                >
                  Sign up
                </NavLink> */}
              </div>
            )}
          </div>

          {/* <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600-to-indigo-600.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid grid-cols-1 gap-7">
                      {solutions.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-indigo-600 to-indigo-600 text-white">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    <a
                      href="#"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Pricing
                    </a>
                    <a
                      href="#"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Partners
                    </a>
                    <a
                      href="#"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Company
                    </a>
                  </div>
                  <div className="mt-6">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center bg-gradient-to-r from-indigo-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-indigo-700 hover:to-indigo-700"
                    >
                      Sign up
                    </a>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?
                      <a href="#" className="text-gray-900">
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition> */}
        </Popover>
      </header>
    </>
  );
}
