// store.js

import React, { createContext, useEffect, useMemo, useState } from "react";
import { utils } from "../modules/_utils";
import { _navigation } from "../modules/_navigation";

export const Context = createContext(null);

export const Store = ({ children }, props) => {
  const [state, setState] = useState({});
  const [cache, setCache] = useState();
  const [flags, setFlags] = useState();

  useEffect(() => {
    (async () => {
      let cache = await utils.getCacheData("flags", "flags");

      if (cache) {
        console.log("USEEFFECT in STORE --> GETTING FLAGS", cache);
        setFlags(cache);
        setCache(cache);
      }
      setState({ ...state, flags: cache.data });
    })();
  }, []);
  // useEffect(() => {
  //   const homelinks = props.homelinks ? props.homelinks : null;
  //   setState({ ...state, homelinks: homelinks });
  // }, [props.homelinks]);

  // useEffect(() => {
  //   let homelinks = _navigation.getHomeLinksByTenantType(
  //     props.profile && props.profile.tenantType
  //       ? props.profile.tenantType
  //       : "admin"
  //   );
  //   if (homelinks) {
  //     setState({ ...state, homeLinks: homelinks });
  //   }
  // }, [props.profile]);

  return (
    <Context.Provider value={state}>
      {/* <- this value is gonna be Global state */}
      {children}
    </Context.Provider>
  );
};
