import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";

import RiskLogo from "../images/risk-logo.png";
import Logo from "../images/BreachConnectLogoBlue.png";

import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import Button from "../elements/Button";
import { usePersistStore } from "../store/usestore";
import { _stripe } from "../modules/_stripe";
import { features } from "../modules/_features";
import Input from "../components/Input";
import { _navigation } from "../modules/_navigation";
import { Store } from "../layouts/store";
import useGlobalState from "../hooks/useGlobalState";
import { audit } from "../modules/_audit";

function Signin() {
  const history = useHistory();
  // const [isAuthenticated, setIsAuthenticated] = useState();
  const { isAuthenticated, isSuccess, profile, error, loginAsync, token } =
    usePersistStore();
  const [loginMessage, setLoginMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const allowedRoles = ["Manager", "Administrator", "Super Admin"];
  // const { updateState } = useGlobalState();
  // useEffect(() => {
  //   features.getFeatureFlags();
  // }, []);

  const auth = async (event) => {
    setLoader(!loader);

    event.preventDefault();
    _auth.setUserProfile({});
    console.info(event);
    var u = event.target.elements.email.value;
    var p = event.target.elements.password.value;
    console.info(u, p);
    let userData = {
      email: u,
      password: p,
    };
    let path = "/onboarding";

    let response = await loginAsync(userData);
    console.log("loginAsync Response", response);
    // debugger;s
    if (response) {
      // console.log("loginAsync Response", response);
      _auth.setUserProfile(response);
      audit.logAction("Login Successful");
      console.log("Getting feature flags");
      // features.getFeatureFlags();
      // let flags = await features.featureFlags();
      // if (flags) {
      //   updateState("flags", flags.data ? flags.data : []);
      // }

      if (response.MFA == true) {
        //if the user has MFA enabled, go to MFA page, regardless how many tenants they have
        console.log("sign in redirect to mfa", response);
        path = "/mfa";
        history.push({
          state: { profile: response },
          pathname: path,
        });
      } else {
        //if the user has MFA disabled, check if they have more than one tenant
        let tenantCount = response.tenantCount;
        let role = response.companyRole;

        if (
          tenantCount > 1
          // ||
          // role in
          //   ["Admin", "Administrator", "SuperAdmin", "Super Administrator"]
        ) {
          //if they have more than one tenant, go to tenant selection page
          path = "/select-tenant";
          history.push({
            state: { profile: response },
            pathname: path,
          });
        } else {
          //update profile with tenant information if they only have one tenant
          response.tenantId = response.tenants[0].tenantId._id;
          response.tenantName = response.tenants[0].tenantId.tenantTitle;
          response.tenantType = response.tenants[0].tenantId.type;

          let isRenamed = response.tenants[0].tenantId.isRenamed;
          response.tenantRole = response.tenants[0].role;

          response = _auth.buildProfile(response);

          console.log("Update user profile", response);
          _auth.setUserProfile(response);
          //if they have one tenant, get their stripe information
          if (process.env.REACT_APP_NODE_ENV !== "development") {
            await _stripe.getStripeData(); //get stripe data and set it in the cache. this also creates websocket connection
          }
          if (
            isRenamed == true ||
            response.role == "Collaborator" ||
            response.role == "Observer"
            //for now, just check isRenamed. We will add more checks later.
            // ||
            // response.dnd == true ||
            // response.onBoardCompleted == true
          ) {
            //check if we need to show onboarding page
            console.log("sign in redirect to home");
            // path = "/home";
            path = _navigation.getPath(response);
            history.push({
              state: { profile: response },
              pathname: path,
            });
          } else {
            history.push({
              state: { profile: response },
              // pathname: "/onboarding",
              pathname: _navigation.getPath(response),
            });
          }
        }
      }
    } else {
      console.log("loginAsync FALSE Response", response);
      //log login failed on backend
      setLoader(false);
    }
  };

  return (
    <main className="bg-neutral-900">
      <div className="relative grow-1 justify-center">
        {/* Content */}
        {/* <div className=" justify-center content-center"> */}
        <div className="grid grid-cols-1 md:grid-cols-2  min-h-screen h-full flex grow-0 flex-col content-center justify-center">
          {/* Begin content split */}
          <div className="flex flex-col h-screen justify-center content-center">
            {/* Left Side */}
            <div className="flex max-w-sm mx-auto px-4">
              {/* <img width="80" className="mx-auto px-4 py-8" src={Logo} /> */}
              <span className="py-8 inline-flex text-3xl m-auto font-normal text-slate-200">
                Sign in to your account{" "}
              </span>
            </div>
            <div className="max-w-sm mx-auto px-4 ">
              {/* <h1 className="text-3xl text-slate-800 font-bold mb-6">Sign in</h1> */}
              {/* Form */}
              <form onSubmit={auth}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1 "
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <Input
                      type="email"
                      placeholder="Work email address"
                      id="email"
                    />
                    {/* <input
                      id="email"
                      className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-secondary-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-secondary-500 focus:ring-1 focus:ring-secondary-500"
                      type="email"
                    /> */}
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <Input
                      type="password"
                      // className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      // label="Work Email address"
                      placeholder="Password"
                      id="password"
                      autoComplete="on"
                    />
                    {/* <input
                      id="password"
                      // className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      className=" rounded-md w-full
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-secondary-500
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-secondary-500 focus:ring-1 focus:ring-secondary-500"
                      type="password"
                      autoComplete="on"
                    /> */}
                  </div>
                </div>
                <div className=" items-center justify-between mt-6">
                  {/* {loader ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading.."
                      />
                    </div>
                  ) : ( */}
                  <div className="text-sm">
                    {/* <div className="py-2">
                      By signing in, you agree to our{" "}
                      <a
                        href="https://www.Midnight Paranormal.com/terms"
                        //open in new window
                        target="_blank"
                        className="font-extra-small text-secondary-600 hover:text-primary-500"
                      >
                        Terms and Conditions
                      </a>
                    </div> */}
                  </div>
                  <Button
                    enabled={!loader}
                    loader={loader}
                    type="submit"
                    className="text-center items-center justify-center"
                    // rounded
                    // disabled
                    fullWidth
                    color="secondary"
                    size="md"
                    // handleClick={null}
                  >
                    Sign in
                  </Button>
                  <div className="mr-1 mt-5">
                    <Link
                      className="text-sm text-white underline hover:text-accent-700"
                      to="/forgot-password"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                {error && (
                  <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                    {error}
                  </div>
                )}
              </form>

              {/* Footer */}
              {isAuthenticated === false && (
                <div className="flex grow-0 items-center justify-center mt-6  text-red-500 text-sm">
                  {/* Username or password is incorrect. */}
                  {loginMessage}
                </div>
              )}
              {/* <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-accent-500 hover:text-accent-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          {/* Right Side */}
          <div className="flex bg-neutral-800 h-screen flex-col justify-center content-center">
            <div className="flex-col justify-center content-center max-w-sm mx-auto px-4">
              <div className="text-center max-w-sm mx-auto px-4">
                {/* <img width="80" className="mx-auto px-4 py-8" src={Logo} /> */}
                <span className=" mx-auto px-4 py-8 inline-flex text-3xl m-auto font-normal text-slate-200">
                  New here?
                </span>
              </div>
              <div className="max-w-sm mx-auto px-4  ">
                <div className="text-medium">
                  Just click the button below and you will be on your way in no
                  time.
                </div>
              </div>
              <div className="max-w-sm mx-auto px-4">
                <div className="w-full pt-5 mt-6 border-t border-gray-200">
                  {/* <div className="text-sm w-full"> */}
                  <Link
                    className="flex items-center text-center justify-center py-1.5 text-sm w-full rounded-full border border-white border-opacity-50 font-medium text-white "
                    to="/public/signup"
                  >
                    Sign Up
                  </Link>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </main>
  );
}

export default Signin;
