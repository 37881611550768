import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import HeaderLinks from "../partials/header/HeaderLinks";
import Logo from "../images/BreachConnectLogoBlue.png";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { FeatureFlag } from "../components/FeatureFlag";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export function HeaderPrivate(props) {
  const profile = props.profile;
  const [currentLink, setCurrentLink] = useState("home");
  const location = useLocation();
  const { pathname } = location;
  const [navigation, setNavigation] = useState([]);

  useEffect(() => {
    console.log("HeaderPrivate props.navigation", props.navigation);
    setNavigation(props.navigation);
  }, [props.navigation]);

  const handleNavClick = (scrollTo, linkName) => {
    // setCurrentLink(linkName);
    executeScroll(scrollTo, linkName);
  };

  const executeScroll = (scrollTo, linkName) => {
    // console.info("executeScroll", hubsForm);
    setCurrentLink(linkName);

    const element = document.getElementById(scrollTo);
    console.info("executeScroll", element);
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      // block: "nearest",
      inline: "start",
    });
  };
  return (
    <>
      <header
        style={{ backgroundColor: "#000000" }}
        className="sticky top-0 z-20  w-full"
      >
        <Popover className="relative w-full ">
          <div className="w-full flex justify-between items-center  mx-auto px-4 sm:px-6 md:justify-start md:space-x-10 ">
            <div className="flex m-auto lg:inline xl:inline 2xl:inline 3xl:inline 4xl:inline ">
              <div className="flex flex-row justify-center content-center">
                {/* <img width="50" className="mr-3 " src={Logo} /> */}
                <span className="inline-flex text-xl m-auto font-medium text-slate-200">
                  Midnight Paranormal
                </span>
              </div>
            </div>

            <div className="hidden sm:hidden md:hidden lg:hidden xl:inline 2xl:inline 3xl:inline 4xl:inline "></div>

            <div className="bg-neutral-950 text-gray-200 ml-8 hidden md:flex items-center justify-start md:flex-1 lg:w-0">
              <HeaderLinks
                showHome={false}
                align="left"
                navigation={navigation}
              />
            </div>

            <div className="mr-8 hidden md:flex items-center justify-end md:flex-1 lg:w-0"></div>
          </div>

          {/* <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600-to-indigo-600.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid grid-cols-1 gap-7">
                      {solutions.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-indigo-600 to-indigo-600 text-white">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    <a
                      href="#"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Pricing
                    </a>
                    <a
                      href="#"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Partners
                    </a>
                    <a
                      href="#"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Company
                    </a>
                  </div>
                  <div className="mt-6">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center bg-gradient-to-r from-indigo-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-indigo-700 hover:to-indigo-700"
                    >
                      Sign up
                    </a>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?
                      <a href="#" className="text-gray-900">
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition> */}
        </Popover>
      </header>
    </>
  );
}
