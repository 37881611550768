// export const baseUrl = "https://prod-api.datasecurityu.com/api/";
// export const baseUrl = "https://beta-api.datasecurityu.com/api/";
// export const baseUrl = "http://localhost:2000/api/";
let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "development") {
  baseUrl = "http://localhost:7001/api/";
} else if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "https://prod-api.midnight-paranormal.com/api/";
} else if (process.env.REACT_APP_NODE_ENV === "beta") {
  baseUrl = "https://beta-api.midnight-paranormal.com/api/";
}
console.info("CURRENT ENVIRONMENT: ", process.env.REACT_APP_NODE_ENV);
console.info("CURRENT BASE URL: ", baseUrl);
export { baseUrl };
