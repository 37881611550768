import { useMemo, useState } from "react";
import { _navigation } from "../modules/_navigation";
import { Header } from "../parts/Header";
import { _auth } from "../modules/_auth";

const LoginLayout = ({ children }) => {
  const [profile, setProfile] = useState(_auth.getUserProfile());
  const navigation = useMemo(() => {
    _navigation.getNavLinksByTenantType("", "loginLayout");
  }, [profile]);
  console.log("loginLayout navigation", navigation);
  return (
    // <Store>
    <div className="flex h-screen w-full overflow-auto md:overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 ">
        <Header
          // showHome={showHome}
          // sidebarOpen={sidebarOpen}
          // setSidebarOpen={setSidebarExpanded}
          profile={null}
          // setSidebarClosed={setSidebarClosed}
          navigation={navigation}
        />
        <main>{children}</main>
      </div>
    </div>
  );
};

export default LoginLayout;
