import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";
const { postRequest, getRequest } = require("./ApiRequest");
export const gallery = {
  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(localStorage.getItem("guestProfile"));
    } else {
      return _auth.getUserProfile();
    }
  },

  async getGallery() {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    if (token) {
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    }

    // try {
    let data = {};
    console.log("gallery/get-gallery before api");

    let response = await getRequest("gallery/get-gallery", data);
    if (response) {
      console.log("gallery/get-gallery", response.data.gallery);
      return response.data.gallery;
    } else {
      console.log("gallery/get-gallery failed", response);
      return false;
    }
  },
};
