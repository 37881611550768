import { postRequest, getRequest, signupPostRequest } from "./ApiRequest";
import setAuthToken from "../config/setAuthToken";
import jwt_decode from "jwt-decode";
import { _auth } from "./_auth";
import { usePersistStore } from "../store/usestore";

export const audit = {
  isProd(email) {
    // return true;
    const prod = process.env.NODE_ENV === "production";
    const local = process.env.NODE_ENV === "local";
    const beta = process.env.NODE_ENV === "beta";

    const userDomain = email ? email.split("@")[1] : "";
    const exemptDomains = [
      "Midnight Paranormal.com",
      "crowdmap.ai",
      "mcgill.ca",
      "gmail.com",
      "bluesmoketech.com",
      "breachconnect.com",
    ];
    if (prod && !exemptDomains.includes(userDomain)) {
      return true;
    } else if (local || beta) {
      return true;
    } else {
      return false;
    }
  },

  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(localStorage.getItem("guestProfile"));
    } else {
      return _auth.getUserProfile();
    }
  },

  async logAction(action, details) {
    let profile = this.profile();
    let auditGuest = false;
    let token = localStorage.getItem("jwtToken");
    if (!token) {
      auditGuest = true;
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    console.info("PROFILE LOG", profile);
    let data = {};
    console.info("AUDIT", action, details);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    data.action = action;
    data.role = profile && profile.role !== undefined ? profile.role : "Guest";
    data.email = profile ? profile.email : "";
    data.name = profile ? profile.name : "";
    data.userId = profile
      ? profile.user_id
        ? profile.user_id
        : profile.userId
      : "";
    data.tenantId = profile ? profile.tenantId : "";
    data.activityDate = today;
    data.details = details;
    data.company = profile ? profile.company : "";
    data.inviteId = profile.inviteId ? profile.inviteId : "";
    data.guestToken = profile.token ? profile.token : "";

    if (audit.isProd(profile.email)) {
      let response;
      //send to server
      console.log("Call  API", action, data);
      if (auditGuest) {
        console.log("CALL API TO AUDIT GUEST");
        response = await postRequest("audit/log-guest", data);
      } else {
        response = await postRequest("audit/log", data);
      }
      if (response) {
        // return response.data;
        return true;
      } else {
        return false;
      }
    } else {
      //log to console
      console.log("Console Log ", action, data);
    }
  },
  getAuditLog() {
    let body = {};
    let profile = this.profile();
    console.log("PROFILE LOG", profile);
    body.tenantId = profile.tenantId;
    console.log("Get Audit Log ", body);
    let data = getRequest("audit/get", body);
    if (data) {
      console.log("Data.data", data);
    }

    return data;
  },
  async getAuditLogByTenant() {
    let body = {};
    let profile = this.profile();
    console.log("PROFILE LOG", profile);
    body.tenantId = profile.tenantId;
    console.log("Get Audit Log ", body);
    let resp = await postRequest("audit/get-all-by-tenant", body);
    if (resp) {
      console.log("audit/get-all-by-tenant", resp);
      return resp.data;
    } else {
      return [];
    }
  },
};
