const colors = require("tailwindcss/colors");
const plugin = require("tailwindcss/plugin");
module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  // content: ["./src/**/*.{js,jsx,ts,tsx}", "./public/index.html"],
  theme: {
    //add scrollbar custom styling
    scroll: {
      thin: {
        //scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full
        //styles below
        thumb: {
          backgroundColor: "#a0aec0",
          borderRadius: "1rem",
          width: "1rem",
        },
        track: {
          backgroundColor: "#edf2f7",
          borderRadius: "1rem",
          width: "1rem",
        },
      },
    },
    extend: {
      scrollbar: {
        scroll:
          "scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full",
      },
      animation: {
        "spin-slow": "spin 3s linear infinite",
      },
      // boxShadow: {
      //   DEFAULT:
      //     "0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.02)",
      //   md: "0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.02)",
      //   lg: "0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01)",
      //   xl: "0 20px 25px -5px rgba(0, 0, 0, 0.08), 0 10px 10px -5px rgba(0, 0, 0, 0.01)",
      //   glow: "inset 0 0 0 2 #6244bb",
      // },
      colors: {
        gray: colors.slate,
        "light-blue": colors.sky,
        red: colors.red,
        neutral: colors.neutral,
        lime: colors.lime,
        indigo: colors.indigo,
        primary: colors.slate,
        navbar: colors.gray,
        secondary: colors.indigo,
        accent: colors.blue,
        tertiary: colors.red,
        beginner: "#ED7D31",
        intermediate: "#FF0BC3",
        advanced: "#01CB01",
        expert: "#0013C6",
        // primary: colors.primary, // colors.purple,
        dsuPurple: "#412267",
        dsuGreen: "#9aca3c",
      },
      // outline: {
      //   blue: "2px solid rgba(0, 112, 244, 0.5)",
      // },
      fontFamily: {
        inter: ["Proxima Nova", "sans-serif"],
      },
      fontSize: {
        xs: ["0.75rem", { lineHeight: "1.5" }],
        sm: ["0.875rem", { lineHeight: "1.5715" }],
        md: ["13px", { lineHeight: "1.5715" }],
        base: ["1rem", { lineHeight: "1.5", letterSpacing: "-0.01em" }],
        lg: ["1.125rem", { lineHeight: "1.5", letterSpacing: "-0.01em" }],
        xl: ["1.25rem", { lineHeight: "1.5", letterSpacing: "-0.01em" }],
        "2xl": ["1.5rem", { lineHeight: "1.33", letterSpacing: "-0.01em" }],
        "3xl": ["1.88rem", { lineHeight: "1.33", letterSpacing: "-0.01em" }],
        "4xl": ["2.25rem", { lineHeight: "1.25", letterSpacing: "-0.02em" }],
        "5xl": ["3rem", { lineHeight: "1.25", letterSpacing: "-0.02em" }],
        "6xl": ["3.75rem", { lineHeight: "1.2", letterSpacing: "-0.02em" }],
      },
      screens: {
        xs: "480px",
        sm: "640px",
        // => @media (min-width: 640px) { ... }

        md: "768px",
        // => @media (min-width: 768px) { ... }

        lg: "1024px",
        // => @media (min-width: 1024px) { ... }

        xl: "1280px",
        // => @media (min-width: 1280px) { ... }

        "2xl": "1536px",
        // => @media (min-width: 1536px) { ... }

        //bootstrap 5 breakpoints

        phone: "576px",
        // => @media (min-width: 640px) { ... }

        tablet: "768px",
        // => @media (min-width: 768px) { ... }

        laptop: "992px",
        // => @media (min-width: 1024px) { ... }

        desktop: "1200px",
        // => @media (min-width: 1280px) { ... }

        widescreen: "1400px",
        // => @media (min-width: 1536px) { ... }
        hd: "1920px",
        qhd: "2560px",
        uhd: "3840px",

        // phone: { max: "480px" },
        // // => @media (max-width: 639px) { ... }
        // tabet: { max: "640px" },
        // // => @media (max-width: 639px) { ... }
        // lap: { max: "1023px" },
        // => @media (max-width: 1023px) { ... }
        // desktop: { min: "1024px" },
        // widescreen: { min: "2048px" },
        // superwidescreen: { min: "3092px" },
      },
      borderWidth: {
        3: "3px",
      },

      minWidth: {
        36: "9rem",
        44: "11rem",
        56: "14rem",
        60: "15rem",
        72: "18rem",
        80: "20rem",
      },
      maxWidth: {
        "8xl": "88rem",
        "9xl": "96rem",
      },
      height: {
        hnav: "h-[calc(100vh-64px)]",
      },
      zIndex: {
        60: "60",
      },
    },
  },
  plugins: [
    // eslint-disable-next-line global-require
    require("@tailwindcss/forms"),
    // require("@tailwindcss/line-clamp"),
    require("tailwind-scrollbar"),
    // require("@tailwindcss/line-clamp"),
    // add custom variant for expanding sidebar
    plugin(({ addVariant, e }) => {
      addVariant("sidebar-expanded", ({ modifySelectors, separator }) => {
        modifySelectors(
          ({ className }) =>
            `.sidebar-expanded .${e(
              `sidebar-expanded${separator}${className}`
            )}`
        );
      });
    }),
  ],
  variants: {
    scrollbar: ["rounded"],
  },
};
