import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "./charts/ChartjsConfig";
import "./css/style.scss";
import "./css/tailwind.css";
//Import Routes
import PrivateRoute from "./routes/PrivateRoute";

//Import Layouts
import Layout from "./layouts/layout";
import LoginLayout from "./layouts/loginLayout";

import Home from "./public-pages/Home";

import ToastNotification from "./components/ToastNotification";
import MarketplaceListingGrid from "./public-pages/marketplace/ListingGrid";
import Directory from "./public-pages/marketplace/Marketplace";
import Signin from "./public-pages/Signin";
import Signup from "./public-pages/Signup";
// OLD

// import ActionPlan from "./pages/ActionPlan";
// import Analytics from "./pages/Analytics";
// import Assessment from "./pages/Assessment";
// import AssessmentHub from "./pages/AssessmentHub";
// import AssessmentSelect from "./pages/AssessmentSelect";
// import Calendar from "./pages/Calendar";
// import Campaigns from "./pages/Campaigns";
// import AccordionPage from "./pages/component/AccordionPage";
// import AlertPage from "./pages/component/AlertPage";
// import AvatarPage from "./pages/component/AvatarPage";
// import BadgePage from "./pages/component/BadgePage";
// import BreadcrumbPage from "./pages/component/BreadcrumbPage";
// import ButtonPage from "./pages/component/ButtonPage";
// import DropdownPage from "./pages/component/DropdownPage";
// import FormPage from "./pages/component/FormPage";
// import IconsPage from "./pages/component/IconsPage";
// import ModalPage from "./pages/component/ModalPage";
// import PaginationPage from "./pages/component/PaginationPage";
// import TabsPage from "./pages/component/TabsPage";
// import TooltipPage from "./pages/component/TooltipPage";
// import ContactPage from "./pages/Contact";
// import Dashboard from "./pages/Dashboard";
// import Cart from "./pages/ecommerce/Cart";
// import Cart2 from "./pages/ecommerce/Cart2";
// import Customers from "./pages/ecommerce/Customers";
// import Invoices from "./pages/ecommerce/Invoices";
// import Orders from "./pages/ecommerce/Orders";
// import Pay from "./pages/ecommerce/Pay";
// import Product from "./pages/ecommerce/Product";
// import Shop from "./pages/ecommerce/Shop";
// import Shop2 from "./pages/ecommerce/Shop2";
// import Employees from "./pages/Employees";
// import ForgotPassword from "./pages/ForgotPassword";
// import Inbox from "./pages/Inbox";
// import LearningHub from "./pages/LearningHub";
// import Marketplace from "./pages/Marketplace";
// import Messages from "./pages/Messages";
// import Onboard from "./pages/Onboard";
// import Onboarding01 from "./pages/Onboarding01";
// import Onboarding02 from "./pages/Onboarding02";
// import Onboarding03 from "./pages/Onboarding03";
// import Onboarding04 from "./pages/Onboarding04";
// import Preassessment01 from "./pages/Preassessment-01";
// import Preassessment02 from "./pages/Preassessment-02";
// import Preassessment03 from "./pages/Preassessment-03";
// import ResetPassword from "./pages/ResetPassword";
import ResetPasswordEmail from "./pages/ResetPasswordEmail";
// import Account from "./pages/settings/Account";
// import Apps from "./pages/settings/Apps";
// import Billing from "./pages/settings/Billing";
// import Feedback from "./pages/settings/Feedback";
// import Notifications from "./pages/settings/Notifications";
// import Plans from "./pages/settings/Plans";

// import SelectTenant from "./pages/SignInSelectTenant";
// import Tasks from "./pages/Tasks";
// import Profile from "./pages/team/Profile";
// import TeamTabs from "./pages/team/TeamTabs";
// import TeamTiles from "./pages/team/TeamTiles";
// import UserImport from "./pages/UserImport";
// import Changelog from "./pages/utility/Changelog";
// import EmptyState from "./pages/utility/EmptyState";
// import Faqs from "./pages/utility/Faqs";
// import PageNotFound from "./pages/utility/PageNotFound";
// import Roadmap from "./pages/utility/Roadmap";
// import SignupConfirm from "./pages/Signup Confirm";
// import MFA from "./pages/MFA";
// import PrivateRoute from "./routes/PrivateRoute";
// import VerifyGuest from "./pages/VerifyGuest";
// import ThankYou from "./pages/ThankYou";
// import AssessmentLayout from "./layouts/assessmentLayout";
// import EmployeeProfile from "./pages/EmployeeProfile";

// import { sockets } from "./modules/_notifications";
// import Header from "./partials/Header";
// //JDO
// import Impediments from "./pages/Impediments";
// import Competencies from "./pages/Competencies";
// import ActionPlansPage from "./pages/ActionPlansPage";
// import ImpedimentDetails from "./pages/impedimentDetails";

// import LayoutTwoColumnMainLeft from "./layouts/LayoutTwoColumnMainLeft";
// import TemplateBuilder from "./pages/TemplateBuilder";
// import TemplateList from "./pages/TemplateList";
// import TemplateViewer from "./pages/TemplateViewer";
// import ProjectList from "./pages/projects/Projects";
// import ProjectDetails from "./pages/projects/ProjectDetails";
// import SolicitationDetails from "./pages/projects/SolicitationDetails";
// import AuditPage from "./pages/AuditPage";
// import VerifyVendor from "./pages/VerifyVendor";
// import VendorReponsePage from "./pages/projects/public/VendorResponsePage";
// import VendorReponseLayoutScrollable from "./layouts/vendor-response-layout-scrollable";
// import VendorWelcomePage from "./pages/projects/public/VendorResponseWelcome";
// import MarketplaceNew from "./pages/marketplace/Marketplace";
// import AcceptInvite from "./pages/AcceptInvite";
// import VendorSignUp from "./pages/projects/public/VendorSignUp";
// import VenderHome from "./pages/vendor/VendorHome";
import WelcomePage from "./private-pages/Home";
import AssessmentLayout from "./layouts/assessmentLayout";
// import VendorRedirect from "./pages/projects/public/VendorRedirect";
// import { _auth } from "./modules/_auth";
// import VendorProjects from "./pages/vendor/VendorProjects";
// import VendorProjectDetails from "./pages/vendor/VendorProjectDetails";
// import BidComparePage from "./pages/projects/BidComparePage";

//JDO

// const socket = sockets.connect();

function Routes() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      {" "}
      {/* <Header /> */}
      <Switch>
        <Route exact path="/public/signup">
          <LoginLayout>
            <Signup />
          </LoginLayout>
        </Route>
        <Route exact path="/reset-password-email/:token/:id">
          <LoginLayout>
            <ResetPasswordEmail />
          </LoginLayout>
        </Route>
        <Route exact path="/public/login">
          <LoginLayout>
            <Signin />
          </LoginLayout>
        </Route>
        <Route exact path="/">
          <LoginLayout>
            <Home />
          </LoginLayout>
        </Route>
        <Route exact path="/public/gallery">
          <LoginLayout>
            <Directory />
          </LoginLayout>
        </Route>
        <PrivateRoute exact path="/home">
          <AssessmentLayout>
            <WelcomePage />
          </AssessmentLayout>
        </PrivateRoute>
        {/* <Route exact path="/terms">
          <LoginLayout>
            <Terms />
          </LoginLayout>
        </Route> */}
        {/* <Route exact path="/pricing">
          <LoginLayout>
            <Pricing />
          </LoginLayout>
        </Route> */}

        {/* <Route path="*">
          <PageNotFound />
        </Route> */}
      </Switch>
      {/* <ToastNotification show={true} socket={socket} /> */}
      {/* </Layout> */}
      {/* </Route> */}
      {/* </Switch> */}
    </>
  );
}

export default Routes;
